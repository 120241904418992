import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import InLineLoader from '../../common/inlineLoader';
import Button from '../../common/button';
import httpHelper from '../../../helpers/HttpHelper';
import environment from '../../common/environments/environments';
import { dashboardNotAllowed } from '../../../helpers/constants';
//import { useScreenType } from '../../../helpers/hooks/useScreenType';
import useScreenSize from '../../../helpers/userScreensize';
import './homeDashboard.scss';
import '../../../styles/global.scss';
const get = require('get-value');

const HomeDashboard = props => {
	const screenSize = useScreenSize(); //eslint-disable-line
	const navigate = useNavigate();
	const { Description, Components } = props.data;
	const http = new httpHelper(); // eslint-disable-line
	const buttonText = get(Components, 'LandingPage.Home.Labels.0.Phrase');
	const [powerBIData, setPowerBIData] = useState(null);
	//const { isSmallDevice } = useScreenType();

	useEffect(() => {
		console.log('Home Page Screen:', screenSize.width); //eslint-disable-line
		if (props.userSelectedlanguage && props.userSelectedlanguage != undefined) getHomePowerBIReport();
		groupAPowerBIAccessCall();
		groupBPowerBIAccessCall();
	}, []);

	useEffect(() => {
		if (props.userSelectedlanguage && props.userSelectedlanguage != undefined) getHomePowerBIReport();
		groupAPowerBIAccessCall();
		groupBPowerBIAccessCall();
	}, [props.userSelectedlanguage]);

	const getHomePowerBIReport = async () => {
		let payload = {};
		await http
			.Get(
				environment.POWER_BI_API +
					'/' +
					props?.groupHomeConfig['GROUPHOME'].trim() +
					'/' +
					props?.groupHomeConfig['WORKSPACE_ID'].trim() +
					'/' +
					props?.userSelectedlanguage?.toLowerCase() +
					'/accesstoken',
				payload
			)
			.then(res => {
				setPowerBIData(res.data);
			})
			.catch(error => {
				//console.log(error);
				return error;
			});
	};

	const groupAPowerBIAccessCall = async () => {
		let payload = {};
		//console.log('propsgroupHomeConfig', props?.groupAConfig);
		await http
			.Get(
				environment.POWER_BI_API +
					'/' +
					props?.groupAConfig['GROUPA'].trim() +
					'/' +
					props?.groupAConfig['WORKSPACE_ID'].trim() +
					'/' +
					props?.userSelectedlanguage.toLowerCase() +
					'/accesstoken',
				payload
			)
			.then(res => {
				window.sessionStorage.setItem('GROUPA_REPORT_ID', res.data.ReportId);
				window.sessionStorage.setItem('GROUPA_EMBED_URL', res.data.EmbedUrl);
				window.sessionStorage.setItem('GROUPA_ACCESS_TOKEN', res.data.AccessToken);
			})
			.catch(error => {
				//console.log(error);
				return error;
			});
	};

	const groupBPowerBIAccessCall = async () => {
		let payload = {};
		//console.log('propsgroupHomeConfig', props?.groupAConfig);
		await http
			.Get(
				environment.POWER_BI_API +
					'/' +
					props?.groupBConfig['GROUPB'].trim() +
					'/' +
					props?.groupBConfig['WORKSPACE_ID'].trim() +
					'/' +
					props?.userSelectedlanguage.toLowerCase() +
					'/accesstoken',
				payload
			)
			.then(res => {
				window.sessionStorage.setItem('GROUPB_REPORT_ID', res.data.ReportId);
				window.sessionStorage.setItem('GROUPB_EMBED_URL', res.data.EmbedUrl);
				window.sessionStorage.setItem('GROUPB_ACCESS_TOKEN', res.data.AccessToken);
			})
			.catch(error => {
				//console.log(error);
				return error;
			});
	};

	return (
		<div className="container-fluid-md container-lg p-1 p-sm-4 p-lg-0 p-xl-0 home-dashboard">
			{!dashboardNotAllowed.includes(props.permissionCode) && (
				<>
					<div className="row p-2">
						<div className="col-12 col-lg-8 home-dashboard__title">{Description}</div>
						<div className="col-12 col-lg-4 home-dashboard__button text-sm-start text-md-start text-lg-end mt-4 mt-md-3">
							<Button
								buttonClass="button--black"
								icon="rightArrowWhite"
								iconPosition="right"
								onClickHandler={() => {
									navigate('/dashboards');
								}}>
								{buttonText}
							</Button>
						</div>
					</div>
					<hr className="horizontal-rule" />
				</>
			)}
			{!dashboardNotAllowed.includes(props.permissionCode) &&
				(powerBIData ? (
					<PowerBIEmbed
						title="Power BI Report"
						embedConfig={{
							type: 'report',
							id: powerBIData.ReportId,
							embedUrl: powerBIData.EmbedUrl,
							accessToken: powerBIData.AccessToken,
							tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
							settings: {
								panes: {
									filters: {
										expanded: false,
										visible: false
									},
									pageNavigation: { visible: false }
								},
								background: models.BackgroundType.Transparent,
								layoutType: models.LayoutType.Custom,
								//layoutType: isSmallDevice ? models.LayoutType.MobilePortrait : models.LayoutType.Custom,
								customLayout: {
									displayOption: models.DisplayOption.FitToPage
								}
							}
						}}
						/* eslint-disable */
						eventHandlers={
							new Map([
								[
									'loaded',
									function () {
										console.log('Report loaded');
									}
								],
								[
									'rendered',
									function () {
										console.log('Report rendered');
									}
								],
								[
									'error',
									function (event) {
										console.log(event.detail);
									}
								],
								[
									'visualClicked',
									function (event) {
										console.log(event.detail);
										navigate('/dashboards');
									}
								],
								['pageChanged', event => console.log(event)]
							])
						}
						cssClassName={'home-dashboard-container'}
						getEmbeddedComponent={embeddedReport => {
							window.report = embeddedReport;
						}}
					/>
				) : (
					<InLineLoader />
				))}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		userSelectedlanguage: state.userSlice.userSelectedlanguage,
		headerPageContent: state.userSlice.headerPageContent
	};
};

const mapDispatchToProps = dispatch => {
	return {
		switchLanguage: shortLang => dispatch(SwitchLanguage(shortLang))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeDashboard);
